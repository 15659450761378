.title {
    &.selected {
        font-size: 22px!important;
    }
    color: #1d6cab;
    font-weight: normal;
    font-size: 1rem;
    margin-bottom: 0px;
}

.container {
    border-bottom: dashed 1px #1d6cab;
}

.icon {
    margin-right: 8px;
    color: #1d6cab;
}

.header {
    &.selected {
        font-size: 26px!important;
        margin-bottom: 1rem;
        margin: 0.5rem;
    }
    display:flex;
    margin: 0.5rem 0.75rem;
    justify-content:space-between;
    align-items:center;
}