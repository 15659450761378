.navbar {
    display: none;
}

.login {
    align-items: center;
    background: linear-gradient(90deg,#495972,#0e2352);
    display: flex;
    height: 100vh;
    justify-content: center;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 2000;
    
    .container {
        max-width: 400px;
    }

    h1 {
        color:white;
        opacity: 0.7;
        font-size: 36px;
        margin-bottom: 1rem;
    }

    h3 {
        color: white;
        font-size: 18px;
        font-weight: normal;
        line-height: 1.6rem;
    }

    a {
        background: #1890ff;
        border-color: #1890ff;
        border-radius: 40px;
        height: 40px;
        margin-top: 10px;
        padding-left: 40px;
        padding-right: 40px;
       
        &:active {
            background-color: #40a9ff!important;
            border-color: #40a9ff!important;
            color: white!important;
        }

        &:focus {
            background-color: #40a9ff!important;
            border-color: #40a9ff!important;
            color: white!important;
            box-shadow: 0 0 0 0.2rem rgba(24, 144, 255, 0.5)!important;
        }

        &:hover {
            background-color: #40a9ff!important;
            border-color: #40a9ff!important;
            color: white!important;
        }
    }

    img {
        width: 250px
    }
}
