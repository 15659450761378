@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/mixins';
@import '../../../assets/style/variables';

.helpModal {
    h5 {
        width: 100%;
    }
    
    .modalHeader {
        padding: 8px 16px;
        background-color: $orange;
        color:white
    }
    
    .header {
        font-size: 20px;
    }
}